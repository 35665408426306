import {alertController, isPlatform, loadingController, toastController} from "@ionic/vue";
import axios from "@/libs/axios";
import {useI18n} from "vue-i18n";

export default () => {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const toastMessage = async (message, tColor)=>{
        toastController.create({position: "top", duration: 3000, message: message, color: tColor}).then((t)=> t.present())
    }

    const deleteKbCat = async (url, msg) => {

        return new Promise((resolve, reject)=>{

            alertController.create({
                cssClass: isDesktop ? 'alert-web' : '',
                header: `${i18n.t('Delete')} ${i18n.t(msg)}`,
                message: i18n.t('action_confirmation'),
                buttons: [
                    {
                        text: i18n.t('Cancel'),
                        cssClass: isDesktop ? 'alert-btn-light mr-1' : '',
                    },
                    {
                        cssClass: isDesktop ? 'alert-btn-danger' : '',
                        text: i18n.t('Confirm'), handler: async () => {
                            const loading = await loadingController.create({message: i18n.t('Please wait...')})
                            await loading.present()
                            axios.delete(url)
                                .then( resp => {
                                    const message = resp.data.success ? i18n.t('Thank you! action completed') : resp.data.message
                                    const color   = resp.data.success ? "primary" : "danger"
                                    if(resp.data.success){
                                        resolve(url)
                                    }
                                    else{
                                        reject(message)
                                    }
                                    toastMessage(message, color)
                                })
                                .catch(error => {
                                    const message = i18n.t('Error')+': '+(error.response ? error.response.status+' '+error.response.statusText : i18n.t('errors.general_error'))
                                    toastMessage(message, 'danger')
                                    reject(message)
                                })
                                .then( () => loading.dismiss())
                        }
                    },
                ]
            }).then((iA) => iA.present())
        })
    }
    return{
        deleteKbCat,
    }
}
