<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Knowledge Base Posts')">
        <block-tools>
          <li>
            <nk-button type="primary" v-on:click="[modalIsOpen=true, modalTitle='Add Knowledge Base Post', getKbCategories(), clearForm()]">
              <nio-icon icon="ni-plus"></nio-icon>
              {{ $t('Add Post') }}
            </nk-button>
          </li>
        </block-tools>
      </block-head>
      <block-content>
        <card no-padding stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header title="">
                <card-tools>
                  <div class="form-inline flex-nowrap gx-3">
                    <div class="form-wrap w-150px">
                      <select class="form-select form-select-md form-control" v-model="kbTopics.order">
                        <option value="desc">{{ $t('Newest Posts') }}</option>
                        <option value="asc">{{ $t('Oldest Posts') }}</option>
                      </select>
                    </div>
                  </div>
                </card-tools>
                <card-tools class="mr-n1">
                  <btn-toolbar>
                    <btn-toolbar-toggle-wrap id="advisors_toolbar">
                      <li>
                        <nk-button is-link type=""
                                   is-icon-only-button=""
                                   class="search-toggle"
                                   v-on:click="searchBarActive=true"
                                   :class="{active: searchBarActive}"
                        >
                          <nio-icon icon="ni-search"></nio-icon>
                        </nk-button>
                      </li>
                    </btn-toolbar-toggle-wrap>
                  </btn-toolbar>
                </card-tools>
              </card-header>
              <card-search
                  :placeholder="$t('Search')+' '+$t('Knowledge Post')"
                  :is-active="searchBarActive"
                  :search-term="kbTopics.search"
                  @do-search="searchTopics"
                  @close-search="[searchBarActive=false, kbTopics.search='']"
              ></card-search>
            </div>
            <div class="d-flex py-5 justify-center" v-if="loadingData">
              <ion-spinner></ion-spinner>
            </div>
            <template v-else>
              <div class="card-inner p-0">
                <special-table>
                  <table-row is-heading>
                    <table-col><span class="sub-text">ID</span></table-col>
                    <table-col break-point="md"><span class="sub-text">{{ $t('Category') }}</span></table-col>
                    <table-col><span class="sub-text">{{ $t('Title') }}</span></table-col>
                    <table-col break-point="lg">{{ $t('Slug') }}</table-col>
                    <table-col break-point="md">{{ $t('Total Views') }}</table-col>
                    <table-col break-point="md">{{ $t('Created At') }}</table-col>
                    <table-col class="nk-tb-col-tools"></table-col>
                  </table-row>

                  <table-row v-for="(kb_topic, index) in kbTopics.data" :key="index">
                    <table-col>{{ kb_topic.id }}</table-col>
                    <table-col break-point="md"> {{ kb_topic.kb_cat.name }}</table-col>
                    <table-col> {{ kb_topic.post_title }}</table-col>
                    <table-col break-point="lg"> {{ kb_topic.post_slug }}</table-col>
                    <table-col break-point="md" v-html="kb_topic.views_count"></table-col>
                    <table-col break-point="md">{{ kb_topic.created_at }}</table-col>
                    <table-col class="nk-tb-col-tools">
                      <ul class="nk-tb-actions gx-1">
                        <li>
                          <drop-down-list>
                            <list-item icon="ni-edit" :text="$t('Edit')" @click="updateKbTopic(kb_topic)"></list-item>
                            <list-item icon="ni-trash" :text="$t('Delete')" @click="deleteKbTopic(kb_topic.id)"></list-item>
                          </drop-down-list>
                        </li>
                      </ul>
                    </table-col>
                  </table-row>

                </special-table>

              </div>
              <div class="card-inner" v-if="pagination.totalRecords > pagination.perPage">
                <pagination
                    v-model="pagination.page"
                    :records="pagination.totalRecords"
                    :per-page="pagination.perPage"
                    @paginate="paginationCallback"
                    :options="{chunk: pagination.perPage, texts:{count: '||'}}"
                />
              </div>
            </template>
          </template>
        </card>
      </block-content>
    </main-block>
    <ion-modal
        :is-open="modalIsOpen"
        @didDismiss="modalIsOpen=false"
        :css-class="isDesktop ? 'modal-web': ''"
    >
      <i-modal :title="$t(modalTitle)" @modal-close="modalIsOpen=false">
        <row class="g-3 align-center mb-3 mb-md-0">
          <column md="6">
            <form-group>
              <label class="form-label" for="cat_name">{{ $t('Select Category') }}</label>
              <form-note>{{ $t('The post name that appears on your site.') }}</form-note>
            </form-group>
          </column>
          <column md="6">
            <form-group>
              <b-select id="lang" v-model="kbTopic.kb_cat_id" size="lg">
                <option value="" selected disabled>{{ $t('Select Category') }}</option>
                <option v-for="(kbCategory, index) in kbCategories" :key="index" :value="kbCategory.id">{{kbCategory.name}}</option>
              </b-select>
            </form-group>
          </column>
        </row>

        <row class="g-3 align-center mb-3 mb-md-0">
          <column md="6">
            <label class="form-label" for="cat_name">{{ $t('Post Name') }}</label>
            <form-note>{{ $t('The post name that appears on your site.') }}</form-note>
            <b-input id="cat_name" v-model="kbTopic.post_title"/>
          </column>
          <column md="6">
            <label class="form-label" for="post_slug">{{ $t('Post Slug') }}</label>
            <form-note>{{ $t('The URL friendly version of name.') }}</form-note>
            <b-input id="post_slug" v-model="kbTopic.post_slug"/>
          </column>
        </row>

        <row class="g-3 align-center mb-3 mb-md-0">
          <column md="12" class="ck-post-content">
            <label class="form-label" for="cat_icon">{{ $t('Post Content') }}</label>
            <form-note>{{ $t('Content that shows of post details') }}</form-note>
            <ckeditor class="border-light border bg-white px-3" v-model="kbTopic.post_content" :editor="ClassicEditor" :config="classicEditorConfig"></ckeditor>
          </column>
        </row>

        <template v-slot:footer class="p-5">
          <nk-button type="primary" class="mr-2" @click="addOrUpdateKbTopic">{{$t('Add')}}</nk-button>
          <a href="javascript:;" class="link link-light" v-on:click="modalIsOpen=false">{{
              $t('Cancel')
            }}</a>
        </template>
      </i-modal>
    </ion-modal>
  </page-template>
</template>

<script>
import {
    IonModal,
    isPlatform,
    IonSpinner, loadingController
} from '@ionic/vue';
import FormNote from "@core/components/bp-form/components/FormNote";
import {
    MainBlock,
    BlockHead,
    BlockContent,
    BlockTools,
    FormGroup,
    Column,
    Row
} from "@/@core/layouts";
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import {BtnToolbar, BtnToolbarToggleWrap} from "@core/components/btn-toolbar"
import "bootstrap"
import {BInput, BSelect} from "@core/components/bp-form";
import Pagination from "v-pagination-3"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import ListItem from "@core/components/dropdown-list/ListItem";
import {DropDownList} from "@core/components";

import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import {reactive, ref, watch, computed} from "vue";
import axios from "@/libs/axios";
import useIonComponents from "@core/IonComponents";
import useKb from "@core/comp-functions/knowledgeBase"
import CKEditor from "@ckeditor/ckeditor5-vue"
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor"
import {classicEditorConfig} from '@/libs/editor-config'
import PageTemplate from "@core/layouts/page/PageTemplate";
export default {
    name: "TopicsList",
    components: {
      PageTemplate,
        BlockTools,
        IonSpinner,
        BlockContent,
        ListItem,
        SpecialTable,
        Pagination,
        TableCol,
        TableRow,
        BtnToolbar,
        BtnToolbarToggleWrap,
        Card,
        CardHeader,
        CardSearch,
        CardTools,
        FormGroup,
        BSelect,
        Column,
        Row,
        BlockHead,
        BInput,
        DropDownList,
        IonModal,
        IModal,
        MainBlock,
        FormNote,
        ckeditor: CKEditor.component,
    },
    setup() {
        let topicsApiUrl = ref('/api/admin/knowledge_base/topics');
        const isDesktop = isPlatform('desktop')
        const modalIsOpen = ref(false)
        const {deleteKbCat} = useKb()
        const deleteModal = ref(false)
        let searchBarActive = ref(false)
        let loadingData = ref(false)
        const {t} = useI18n()
        const {IonSimpleAlert, IonToast} = useIonComponents()
        let modalTitle = ref('')
        let message = ref('')
        let spinners = ref(false)
        let kbCategories = ref([])

        let kbTopic = reactive({
            id: 0,
            kb_cat_id: '',
            post_title: '',
            post_slug: '',
            post_content: '',
        })

        let kbTopics = reactive({
            data: [],
            search: '',
            order: 'desc',
        })

        let pagination = reactive({
            page: 1,
            perPage: 5,
            totalRecords: 0,
        })
        const getKbCategories = async () => {
            let loader = await loadingController.create({message: t('Please wait...')})
            await loader.present()
            await axios.get(`/api/admin/knowledge_base/cat/list`)
                .then(async res=>{
                    await loader.dismiss()
                    kbCategories.value = await res.data.data.data
                })
                .catch(er => {
                    IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
                }).then(()=> loader.dismiss())
        }

        watch(
            () => kbTopic.post_title,
            (val) => {
                kbTopic.post_slug = val.toLowerCase()
                    .replace(/[^\w ]+/g, '')
                    .replace(/ +/g, '-');
            },
            { deep: true }
        )

        const addOrUpdateKbTopic = async () => {
            let loader = await loadingController.create({message: t('Please wait...')})
            await loader.present()
            axios.put(`${topicsApiUrl.value}/add`, kbTopic)
                .then(rs => {
                    loader.dismiss()
                    if (!rs.data.success) {
                        IonSimpleAlert('Error', rs.data.message, 'OK')
                    } else {
                        modalIsOpen.value = false
                        if (kbTopic.id != 0) message = 'Congratulation! Knowledge Base Post Updated Successfully!'
                        else message = 'Congratulation! Knowledge Base Post Created Successfully!'
                        IonToast({
                            header: t('Success'),
                            message: t(message),
                            position: 'top',
                            color: 'primary',
                            duration: 3000
                        });
                        loadTopics();
                    }
                })
                .catch(er => {
                    IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
                })
                .then(() => loader.dismiss())
        }
        let cancelToken = false

        const loadTopics = async () => {
            loadingData.value = true
            if(false !== cancelToken){
              cancelToken()
              cancelToken = false
            }
            axios.get(apiUrl.value,{
              cancelToken: new axios.CancelToken( c => {
                cancelToken = c
              })
            }).then((res) => {
                    kbTopics.data = res.data.data.data
                    pagination.totalRecords = res.data.data.total
                    loadingData.value = false
                }).catch((er) => {
                IonSimpleAlert('Error', er.response ? er.response.status + ' ' + er.response.statusText : 'errors.general_error', 'OK')
            }).then(() => loadingData.value = false)
        }

        const updateKbTopic = (kb_topic) => {
            getKbCategories();
            Object.assign(kbTopic, kb_topic)
            modalTitle.value = "Update Knowledge Base Post"
            modalIsOpen.value = true
        }

        const deleteKbTopic = async (id) => {
            await deleteKbCat(`${topicsApiUrl.value}/delete/${id}`,'Knowledge Base Post');
            await loadTopics();
        }

        const clearForm = () => {
            Object.assign(kbTopic, {
                id: 0,
                kb_cat_id: '',
                post_title: '',
                post_slug: '',
                post_content: '',
            })
        }

        const apiUrl = computed(() => {
            return `${topicsApiUrl.value}/list?page=${pagination.page}&per_page=${pagination.perPage}&order=${kbTopics.order}&search=${kbTopics.search}`;
        })

        watch(apiUrl, () => {
            loadTopics();
        })

        const searchTopics = (val) => {
            kbTopics.search = val
        }

        const paginationCallback = async (val) => {
            pagination.page = val
        }

        loadTopics();

        return {
            loadTopics,
            kbTopics,
            kbTopic,
            searchTopics,
            kbCategories,
            deleteKbTopic,
            getKbCategories,
            addOrUpdateKbTopic,
            updateKbTopic,

            pagination,
            paginationCallback,
            isDesktop,
            loadingData,
            modalTitle,
            modalIsOpen,
            deleteModal,
            searchBarActive,
            apiUrl,
            spinners,
            clearForm,
            topicsApiUrl,
            ClassicEditor,
            classicEditorConfig
        }
    }
}
</script>

<style>
.ck-post-content .ck-reset .ck.ck-editor__main .ck-editor__editable_inline {
    min-height: 200px;
}
</style>
